import React, { useCallback, useMemo } from "react"
import { connect } from 'react-redux'
import { toggleFilter } from "../../state/filter"
import { getUser } from "../../services/auth"
import RenderHomeType from "../../components/Filter/RenderHomeType"
import { doFilter } from "../../services/filter"

const SidebarFilter = ({ dispatch, activeFilters, currentCategory, items }) => {
  const user = getUser();
  const Location = user.Location;
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  const onChange = value => {
  }

  //console.log(segment);
  // default filters 
  var filters = [];
  var filterList = {
    '': [
      'Style', 'HomeType', 'Room', 'PriceSlider'/*, 'Designer' */
    ],
    'explore': [
      'Style', 'HomeType', 'Room', 'PriceSlider'/*, 'Designer' */
    ],
    'home-designs': [
                /*'HomeType', */'Storeys', 'Bed', 'Bath', 'Garage', 'LivingSpaces', 'Room', 'PriceSlider',/* 'LotWidth'*/
    ],
    'facades': [
      'Storeys'/*'Style', 'HomeType', 'Storeys', 'PriceSlider'*/
    ],
    'land': [
      'AddressSearch', 'LandArea', 'LandSize', 'PriceSlider'
    ],
    'style': [
      'Style', 'PriceSlider'/*, 'Designer' */
    ],
    'finishes-fixtures': [
      'SubCategory'/*, 'Style'*/, 'Colour', 'Category',/* 'Finish', 'PriceSlider', 'Brand'*/
    ],
    'furniture': [
      /*'SubCategory', 'Style', 'Colour', 'PriceSlider', 'Brand'*/
    ],
    'cabinetry': [
      /*'SubCategory', 'Style', 'Room', 'Colour', 'PriceSlider'*/
    ]
  };

  if (typeof filterList[currentCategory] !== 'undefined') {
    filters = filterList[currentCategory];
  }

  var categories = [
    { name: "All", catName: "explore", URLSegment: "/explore/", linkClass: "link" },
    { name: "Style", catName: "style", URLSegment: "/explore/style/", linkClass: "link" },
    { name: "Home Designs", catName: "home-designs", URLSegment: "/explore/home-designs/", linkClass: "link" },
    { name: "Facades", catName: "facades", URLSegment: "/explore/facades/", linkClass: "link" },
    { name: "Fixtures & Finishes", catName: "finishes-fixtures", URLSegment: "/explore/finishes-fixtures/", linkClass: "link" },
    { name: "Cabinetry", catName: "cabinetry", URLSegment: "/explore/cabinetry/", linkClass: "link" },
    { name: "Furniture", catName: "furniture", URLSegment: "/explore/furniture/", linkClass: "link" },
  ]

  const categoryNav = useMemo(() => {
    categories.forEach(category => {
      let categoryName = category.catName
      if (currentCategory == categoryName) {
        category.linkClass = 'active';
      }
    })
    return (
      <ul className="category-nav">
        {categories.map((item, idx) => (
          <li key={`category-${idx}`}>
            <a href={`${item.URLSegment}`} className={item.linkClass}>{item.name}</a>
          </li>
        ))}
      </ul>
    )
  }, [currentCategory])

  const onRenderItem = useCallback(filter => {
    if (!items) return true;
    const _filters = doFilter([filter], items)
    return _filters.length > 0
  }, [items])

  return (
    <>
      <div className="sidebar-category">
        {categoryNav}
      </div>
      {filters.length > 0 && (
        <>
          <div className="sub-title sub-title-filter d-none d-lg-flex">
            <span>filter</span>
            <button
              className="filter-reset"
              onClick={() => {
                window.location.href = url;
              }}
            >
              <span>RESET</span>
              <img src="/img/explore/refresh.svg" />
            </button>
          </div>
          <div className="filter-modal-container filter-modal-container-custom">
            <RenderHomeType isMobile={false} list={filters} activeFilters={activeFilters} onSelectItem={filter => {
              dispatch(toggleFilter(filter))
            }} onRenderItem={filter => onRenderItem(filter)} />
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active,
  currentCategory: state.nav.current,
})

export default connect(mapStateToProps, null)(SidebarFilter)